.testimonial {
	&:link, &:active, &:visited, &:hover, &:focus {
		text-decoration: none;
		color: $black;
	}

	&:hover {
		h5 {
			color: $blue;
			text-decoration: underline;
		}
	}
}