// ==========[ BUTTONS ]==========

.btn {
  padding        : 9px 18px;
  border-radius  : 0;
  text-transform : uppercase;
  font-size      : 16px;
  letter-spacing : .5px;
}

.btn-primary {
  color            : $white !important;
  border           : 2px solid $blue;
  background-color : $blue;

  &:hover {
    color            : $white;
    border           : 2px solid $black;
    background-color : $black;
  }
}

.btn-white {
  color            : $white;
  border           : 2px solid $white;
  background-color : transparent;

  &:hover {
    color            : $blue;
    border           : 2px solid $white;
    background-color : $white;
  }
}

.btn-filter,
.btn-secondary {
  color            : $grey-dark;
  border           : 2px solid $grey-light;
  background-color : $grey-light;
  font-size        : 16px;

  &.active,
  &:hover {
    color            : $white;
    border           : 2px solid $blue;
    background-color : $blue;
  }
}

.btn-link {
  color          : $black;
  text-transform : uppercase;
  font-size      : 16px;
  letter-spacing : .5px;

  &:hover {
    color           : $blue;
    text-decoration : none;
  }
}

// ==========[ DROPDOWNS ]==========

.nav-item.dropdown{

  .dropdown-menu {
    margin: -0.125rem 0 0;
  }

  &:hover{
    >.dropdown-menu {
      display: block;
    }
  }
}

.dropdown-menu.show {
  border        : 0;
  border-radius : 0;
  box-shadow    : 0 0 15px rgba(0, 0, 0, .16);

  .dropdown-item {
    color          : $black;
    text-transform : uppercase;
    font-size      : 16px;
    letter-spacing : .5px;

    &.active {
      color            : $white;
      background-color : lighten($blue, 15%);
    }

    &:hover {
      color            : $white;
      background-color : $blue;
    }
  }
}

// ==========[ HELPER CLASSES ]==========

.title-divider {
  display          : block;
  width            : 50px;
  height           : 1px;
  margin           : 20px auto;
  background-color : $grey-light;
}

.full-divider {
  display          : block;
  width            : 100%;
  height           : 1px;
  background-color : $grey-light;
}

.price {
  color          : $black;
  text-transform : uppercase;
  font-family    : $ff-karla;
  font-size      : 18px;
  font-weight    : bold;
  letter-spacing : .5px;
}

.success {
  color     : #3FCE5B;
  font-size : 21px;
}

// ==========[ QUOTE ]==========

.quote {
  padding : 50px 100px;
  border  : 1px solid $grey-light;

  h4 {
    color       : $grey-dark;
    text-align  : center;
    font-family : $ff-lora;
    font-style  : italic;
    line-height : 35px;
  }

  .quote-name {
    display         : flex;
    align-items     : center;
    justify-content : center;

    .line {
      width            : 30px;
      height           : 1px;
      margin-right     : 10px;
      background-color : $grey-light;
    }

    p {
      text-transform : uppercase;
      font-family    : $ff-karla;
      font-size      : 16px;
      letter-spacing : .5px;
    }
  }
}

// ==========[ PARTNER SLIDER ]==========

.partner-slider {
  padding          : 45px 0;
  background-color : $grey-light;
}

// ==========[ BREADCRUMBS ]==========

.breadcrumbs {
  display         : flex;
  align-items     : center;
  justify-content : flex-start;

  a {
    color           : $grey;
    text-decoration : none;
    text-transform  : uppercase;
    font-size       : 16px;
    letter-spacing  : 2px;

    &:hover {
      color : $blue;
    }
  }

  i,
  svg {
    color     : $grey;
    font-size : 12px;
  }
}

// ==========[ SOCIAL MEDIA SHARING ]==========

.sharing {
  h6 {
    color          : $blue;
    text-transform : uppercase;
    font-size      : 16px;
    font-weight    : bold;
    letter-spacing : .5px;
  }

  a {
    color     : $blue;
    font-size : 18px;

    &:hover {
      color           : $black;
      text-decoration : none;
    }
  }
}

.social {
  color     : $black;
  font-size : 21px;

  &:hover {
    color           : $blue;
    text-decoration : none;
  }
}


// ==========[ wysiwyg ]==========

.picture-item{
  position: relative;
  .overlay {
    @include           quart-transition(400ms);
    display          : flex;
    position         : absolute;
    top              : 0;
    right            : 0;
    bottom           : 0;
    left             : 0;
    opacity          : 0;
    background-color : transparentize($black, .7);
    align-items      : center;
    justify-content  : center;

    .button {
      @include           quart-transition(400ms);
      display          : flex;
      width            : 40px;
      height           : 40px;
      color            : $white;
      background-color : $blue;
      align-items      : center;
      justify-content  : center;
      transform        : scale(0);
    }
  }

  &:hover {
    .overlay {
      opacity : 1;

      .button {
        transform : scale(1);
      }
    }
  }
}

.slick-prev, .slick-next{
  &:before{
    content: '';
  }
  z-index: 2;
  display          : flex;
  width            : 40px;
  height           : 40px;
  color            : $white;
  background-color : $blue;
  align-items      : center;
  font-size        : 18px;
  justify-content  : center;

  &:hover {
    @include           softshadow;
    color            : $blue;
    background-color : $white;
  }
  &:focus{
    background-color : $blue;
    color: $white;
  }
}
.slick-prev{
  left: 0;
}
.slick-next{
  right: 0;
}

.cms-video{
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
}


// ==========[ TROEVEN ]==========

.troeven {
  .troef {
    display         : flex;
    margin-bottom   : 10px;
    align-items     : flex-start;
    justify-content : flex-start;

    &:last-child {
      margin-bottom : 0;
    }

    i,
    svg {
      margin-top   : 2px;
      margin-right : 20px;
      color        : $blue;
      font-size    : 21px;
      flex         : 0 0 21px;
    }
  }
}

.extra-block{
  .full-divider:last-of-type{
    display: none;
  }
}

.image-block {
  display            : block;
  position           : relative;
  width              : 100%;
  padding-top        : 75%;
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : cover;

  &.square {
    padding-top: 100%;
  }

  &.long {
    padding-top: 133%;
  }

  &.banner {
  	padding-top: 35%;
  }
}

.partner-slider {
	img {
		height: 50px;
	}
}
