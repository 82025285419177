// Extra small devices (portrait phones, less than 576px)

@media
  (max-width : 575.98px) {
  .off-canvas-nav {
    right : -100vw;
    width : 100vw;
  }

  .header-home {
    height : 400px;
  }

  .home-grid {
    .grid-item,
    .grid-sizer {
      width : 100% !important;
    }

    .grid-block {
      padding-top : 50%;
    }

    .grid-dienst {
      padding-top : 50% !important;

      &.half-height {
        .overlay {
          right : 20px;
        }
      }
    }
  }

  .promotie-banner {
    height  : auto;
    padding : 50px;

    h1 {
      max-width : 100%;
    }

    h5 {
      max-width : 100%;
    }
  }

  .partner-slider {
    padding : 20px 0;
  }

  .image-grid,
  .nieuws-grid {
    .grid-item,
    .grid-sizer {
      width : 100% !important;
    }

    .gallery-image {
      &.double-height {
        padding-top : 75%;
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)

@media
  (max-width : 767.98px) {
  .cta {
    &.home{
      margin-top : 30px !important;
    }
  }

  .quote {
    padding : 40px;
  }

  footer {
    .sub-footer {
      padding        : 20px 15px;
      align-items    : flex-start;
      flex-direction : column;
    }
  }

  .image-grid {
    .grid-item,
    .grid-sizer {
      width : calc(50% - 17.5px);
    }
  }
}

// Medium devices (tablets, less than 992px)

@media
  (max-width : 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right : 15px;
    padding-left  : 15px;
  }

  .top-bar {
    .container {
      flex-direction : column;

      h6 {
        margin-bottom : 6px;
      }
    }
  }

  .cta {
    &.home{
      margin-top     : -66px;
    }
    flex-direction : column;

    h4 {
      margin-bottom : 15px;
    }
  }

  .home-grid {
    .grid-item,
    .grid-sizer {
      width : calc(50% - 4px);
    }

    .grid-dienst {
      &.half-height {
        padding-top : 100%;
      }
    }
  }

  .nieuwsbrief-cta {
    flex-direction : column;

    form {
      input {
        min-width : auto;
      }
    }
  }

  .nieuws-grid {
    .grid-item,
    .grid-sizer {
      width : calc(50% - 17.5px);
    }
  }
}

// Large devices (desktops, less than 1200px)

@media
  (max-width : 1199.98px) {
}
