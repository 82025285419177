// ==========[ GENERAL ]==========

* {
	font-feature-settings   : "kern" 1;
	font-family             : $ff-karla;
	font-kerning            : normal;
	-webkit-font-smoothing  : antialiased;
	-moz-osx-font-smoothing : grayscale;
}

body {
	color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin  : 0;
	padding : 0;
}

h1, .uk-h1 {
	text-transform : uppercase;
	font-size      : 48px;
	font-weight    : bold;
	line-height    : 58px;
	font-weight: normal;
}

h2, .uk-h2 {
	color       : $blue;
	font-family : $ff-lora;
	font-size   : 32px;
	font-weight: normal;
}

h3, .uk-h3 {
	color          : $black;
	text-transform : uppercase;
	font-size      : 26px;
	font-weight: normal;
}

h4, .uk-h4 {
	font-size : 22px;
	font-weight: normal;

	&.title {
		color       : $black;
		font-family : $ff-lora;
		font-weight : bold;
	}
}

h5, .uk-h5 {
	color          : $blue;
	text-transform : uppercase;
	font-size      : 18px;
	font-weight    : bold;
}

h6, .uk-h6 {
	font-size : 16px;
	font-weight: normal;

	&.sub-title {
		color          : $black;
		text-transform : uppercase;
		font-size      : 16px;
		letter-spacing : 2px;
	}
}

.cms,
p {
	font-family : $ff-lora;
	font-size   : 16px;

	b {
		font-family : $ff-lora;
	}
	a {
		color           : $blue;
		text-decoration : underline;

		&:hover {
			color           : $black;
			text-decoration : none;
		}
	}
}

.cms{
	&.two-columns{
		column-count: 2;
	}
	&.three-columns{
		column-count: 3;
	}
	p{
		margin-bottom: 1rem;
	}
}

.cms h2, .cms h3, .cms h4, .cms h5{
	margin-bottom: initial;
}

.dropdown-item.active, .dropdown-item:active{ background-color: $blue; }
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{ background-color: $blue; }

.mt-48 { margin-top: 48px; }
.mb-48 { margin-bottom: 48px; }

// ==========[ HEADER || NAVIGATIE ]==========

.top-bar {
	padding-top      : 10px;
	padding-bottom   : 10px;
	background-color : $grey-lightest;

	.container {
		display         : flex;
		align-items     : center;
		justify-content : space-between;

		h6 {
			color     : $black;
			font-size : 16px;

			a {
				color           : $black;
				text-decoration : underline;

				&:hover {
					color           : $grey-dark;
					text-decoration : none;
				}
			}
		}

		ul {
			display         : flex;
			margin          : 0;
			padding         : 0;
			align-items     : center;
			justify-content : flex-end;
			list-style-type : none;

			li {
				margin-right : 30px;

				&:last-child {
					margin-right : 0;
				}

				a {
					color          : $blue;
					text-transform : uppercase;
					font-size      : 16px;
					letter-spacing : .5px;
				}
			}
		}
	}
}

.navbar {
	padding       : 27px 0;
	border-bottom : 1px solid $grey-light;

	.container {
		display: block;
	}

	.navbar-brand {
		padding : 0;
	}

	.nav-item {
		padding-right : 8px;
		padding-left  : 8px;

		&:first-child {
			padding-left : 0;
		}

		&:last-child {
			padding-right : 0;
		}

		.nav-link {
			padding        : 0;
			color          : $blue;
			text-transform : uppercase;
			font-size      : 16px;
			font-weight    : normal;
			letter-spacing : .5px;

			&:hover {
				color : $black;
			}
		}

		.nav-search {
			position  : relative;
			color     : $blue;
			font-size : 18px;

			&:hover {
				color : $black;
			}
		}

		&.active {
			.nav-link {
				font-weight : bold;
			}
		}
	}
}

// ==========[ FOOTER ]==========

footer {
	background-color : $black;

	h6 {
		color          : $white;
		text-transform : uppercase;
		font-weight    : bold;
	}

	.subnav {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			display: block;
			padding: 0;
			margin: 0;

			a {
				&:link, &:active, &:visited, &:hover, &:focus {
					text-decoration: none;
					color: $grey;
					font-size: 16px;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.cms {
		color       : $grey;
		font-family : $ff-karla;
		line-height : 21px;

		a {
			color           : $grey;
			text-decoration : underline;

			&:hover {
				color           : $white;
				text-decoration : none;
			}
		}
	}

	.social {
		color           : $grey;
		text-decoration : none;
		font-size       : 18px;

		&:hover {
			color : $white;
		}
	}

	form {
		input {
			width            : 100%;
			padding          : 10px 0;
			color            : $white;
			border           : 0;
			border-bottom    : 1px solid $blue;
			background-color : transparent;
			font-size        : 16px;

			&::placeholder {
				color : $grey;
			}

			&:focus {
				border-bottom : 1px solid $white;
				outline       : none;
				box-shadow    : 0;
			}
		}
	}

	.sub-footer {
		display         : flex;
		padding         : 20px 0;
		border-top      : 1px solid $blue;
		align-items     : center;
		justify-content : space-between;

		p {
			color       : $grey;
			font-family : $ff-karla;

			a {
				color           : $grey;
				text-decoration : underline;

				&:hover {
					color           : $white;
					text-decoration : none;
				}
			}
		}
	}
}

// ==========[ HOME ]==========

.header-home {
	height              : 600px;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;

	.container {
		display         : flex;
		height          : 100%;
		
		flex-direction  : column;
		

		&.left_top {
			align-items     : start;
			justify-content : flex-start;
		}

		&.right_top {
			align-items     : end;
			justify-content : flex-start;
		}

		&.right_bottom {
			align-items     : end;
			justify-content : flex-end;
			padding-bottom: 75px;
		}

		&.left_bottom {
			align-items     : start;
			justify-content : flex-end;
			padding-bottom: 75px;
		}

		&.center {
			align-items     : center;
			justify-content : center;
		}

		h1 {
			color : $white;
		}
	}
}

.cta{

	display          : flex;
	
	padding          : 35px 40px;
	background-color : $blue;
	align-items      : center;
	justify-content  : space-between;
	&.home{
		margin-top       : -48px;
	}
	h4 {
		color       : $white;
		font-family : $ff-lora;
		font-style  : italic;
	}

	.cta__a {
		color          : $white;
		text-transform : uppercase;
		font-size      : 16px;
		letter-spacing : .5px;

		i,
		svg {
			@include      quart-transition(400ms);
			margin-left : 5px;
		}

		&:hover {
			text-decoration : none;

			i,
			svg {
				margin-left : 15px;
			}
		}
	}
}

.home-grid {
	.grid-item,
	.grid-sizer {
		width         : calc(25% - 4px);
		margin-bottom : 4px;
	}

	.grid-item-double {
		width : calc(50% - 4px);
	}

	.grid-block {
		@include      quart-transition(400ms);
		display     : block;
		position    : relative;
		width       : 100%;
		padding-top : 100%;
		overflow    : hidden;
		background: $grey-lightest;

		.bg-image {
			@include              quart-transition(400ms);
			position            : absolute;
			top                 : 0;
			right               : 0;
			bottom              : 0;
			left                : 0;
			background-repeat   : no-repeat;
			background-position : center;
			background-size     : cover;
		}

		.overlay {
			@include           quart-transition(400ms);
			position         : absolute;
			top              : 0;
			right            : 0;
			bottom           : 0;
			left             : 0;
			background-color : transparentize($black, .7);

			h5 {
				@include         quart-transition(400ms);
				position       : absolute;
				right          : 0;
				bottom         : 50px;
				left           : 0;
				color          : $white;
				opacity        : 1;
				text-align     : center;
				text-transform : uppercase;
				font-size      : 18px;
				font-weight    : bold;
				letter-spacing : .5px;
			}

			.hover-text {
				bottom  : 20px;
				opacity : 0;
			}
		}

		&:hover {
			.bg-image {
				transform : scale(1.1);
			}

			.overlay {
				background-color : transparentize($black, .85);

				h5 {
					bottom  : 80px;
					opacity : 0;
				}

				.hover-text {
					bottom  : 50px;
					opacity : 1;
				}
			}
		}
	}

	.grid-dienst {
		@include      quart-transition(400ms);
		display     : block;
		position    : relative;
		width       : 100%;
		padding-top : 100%;
		overflow    : hidden;

		.bg-image {
			@include              quart-transition(400ms);
			position            : absolute;
			top                 : 0;
			right               : 0;
			bottom              : 0;
			left                : 0;
			background-repeat   : no-repeat;
			background-position : center;
			background-size     : cover;
		}

		.overlay {
			@include           quart-transition(400ms);
			position         : absolute;
			right            : 20px;
			bottom           : 20px;
			left             : 20px;
			padding          : 15px 30px;
			background-color : transparentize($black, .4);

			h5 {
				margin-bottom  : 10px;
				padding-bottom : 5px;
				color          : $white;
				border-bottom  : 1px solid $white;
				font-family    : $ff-lora;
				font-size      : 22px;
				font-style     : italic;
			}

			p {
				color       : $white;
				font-family : $ff-karla;
				font-size   : 16px;
				font-weight : normal;
			}
		}

		&:hover {
			.bg-image {
				transform : scale(1.1);
			}

			.overlay {
				background-color : transparentize($black, .2);
			}
		}

		&.half-height {
			padding-top : calc(50% - 2px);

			.overlay {
				right : auto;
			}
		}
	}
}

.banner-text {
	position: absolute;
	top: 45%;
	left: 0;
	width: 100%;

	h2 {
		color: $white;
	}
}

.promotie-banner {
	display             : flex;
	width               : 100%;
	height              : 300px;
	padding             : 50px;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;
	align-items         : flex-start;
	flex-direction      : column;
	justify-content     : flex-end;

	h1 {
		max-width      : 50%;
		color          : $blue;
		letter-spacing : .5px;
	}

	h5 {
		max-width   : 50%;
		color       : $black;
		font-family : $ff-lora;
		font-style  : italic;
		line-height : 27px;
	}
}

// ==========[ NIEUWS ]==========

.nieuws-grid {
	.grid-item,
	.grid-sizer {
		width         : calc(33% - 17.5px);
		margin-bottom : 30px;
	}
}

.nieuws-header {
	position            : relative;
	width               : 100%;
	padding-top         : 40%;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;

	.date {
		@include           quart-transition(400ms);
		display          : flex;
		position         : absolute;
		top              : 0;
		left             : 20px;
		padding          : 7px 13px;
		background-color : $white;
		align-items      : center;
		flex-direction   : column;
		justify-content  : center;

		.day {
			@include      quart-transition(400ms);
			color       : $black;
			font-size   : 26px;
			line-height : 36px;
			&.small{
				font-size: 16px;
			}
		}

		.month {
			@include         quart-transition(400ms);
			color          : $black;
			text-transform : uppercase;
			font-size      : 16px;
			font-weight    : bold;
			line-height    : 15px;
		}
	}

	.tag {
		position         : absolute;
		bottom           : 0;
		left             : 0;
		padding          : 11px 20px;
		color            : $white;
		background-color : $blue;
		text-transform   : uppercase;
		font-size        : 16px;
	}
}

.nieuws-item {
	display : block;

	.image {
		position            : relative;
		//width               : 100%;
		margin-bottom       : 30px;
		//padding-top         : 40%;
		//background-repeat   : no-repeat;
		//background-position : center;
		//background-size     : cover;
		height: 185px;
		overflow: hidden;

		.date {
			@include           quart-transition(400ms);
			display          : flex;
			position         : absolute;
			top              : 0;
			left             : 20px;
			padding          : 7px 13px;
			background-color : $white;
			align-items      : center;
			flex-direction   : column;
			justify-content  : center;

			.day {
				@include      quart-transition(400ms);
				color       : $black;
				font-size   : 26px;
				line-height : 36px;
				&.small{
					font-size: 16px;
				}
			}

			.month {
				@include         quart-transition(400ms);
				color          : $black;
				text-transform : uppercase;
				font-size      : 16px;
				font-weight    : bold;
				line-height    : 16px;
			}
		}
	}

	.content {
		.title {
			color       : $black;
			font-family : $ff-lora;
			line-height : 26px;
		}

		.cms {
			color : $grey-dark;
		}
	}

	&:hover {
		text-decoration : none;

		.date {
			background-color : $blue;

			.day {
				color : $white;
			}

			.month {
				color : $white;
			}
		}

		.content {
			.cms,
			.title {
				color : $blue;
			}
		}
	}
}

.nieuwsbrief-cta {
	display         : flex;
	padding         : 30px 40px;
	border          : 1px solid $grey-light;
	align-items     : center;
	justify-content : space-between;

	h4 {
		color       : $black;
		font-family : $ff-lora;
		font-style  : italic;
	}

	form {
		display         : flex;
		align-items     : stretch;
		justify-content : flex-end;

		input {
			display      : block;
			min-width    : 250px;
			margin-right : 10px;
			padding      : 0 20px;
			color        : $grey-dark;
			border       : 1px solid $grey-light;
			font-size    : 16px;

			&::placeholder {
				color : $grey;
			}

			&:focus {
				border     : 1px solid $grey-dark;
				outline    : none;
				box-shadow : 0;
			}
		}
	}
}


// ==========[ FORMS ]==========

form {
	label {
		padding-top : 3px;
		color       : $black;
		font-size   : 16px;
		font-weight : bold;
	}

	.form-control {
		padding-top   : 5px;
		color         : $black;
		border        : 2px solid $grey-light;
		border-radius : 0;
	}
}

// ==========[ PRODUCTEN ]==========

.product-item {
	display : block;

	.image {
		position            : relative;
		width               : 100%;
		padding-top         : 75%;
		background-repeat   : no-repeat;
		background-position : center;
		background-size     : cover;

		.overlay {
			@include           quart-transition(400ms);
			display          : flex;
			position         : absolute;
			top              : 0;
			right            : 0;
			bottom           : 0;
			left             : 0;
			opacity          : 0;
			background-color : transparentize($black, .7);
			align-items      : center;
			justify-content  : center;

			.button {
				@include           quart-transition(400ms);
				display          : flex;
				width            : 40px;
				height           : 40px;
				color            : $white;
				background-color : $blue;
				align-items      : center;
				justify-content  : center;
				transform        : scale(0);
			}
		}

		&:hover {
			.overlay {
				opacity : 1;

				.button {
					transform : scale(1);
				}
			}
		}
	}

	.content {
		h5 {
			color          : $grey-dark;
			text-transform : uppercase;
			font-weight    : bold;
			letter-spacing : .5px;
		}

		.text {
			display         : flex;
			color           : $grey-dark;
			align-content   : flex-start;
			justify-content : space-between;
		}

		.left {
			flex : 0 1 auto;
		}

		.right {
			display         : flex;
			align-items     : flex-end;
			flex            : 1 0 auto;
			flex-direction  : column;
			justify-content : flex-start;
		}
	}

	&:hover {
		text-decoration : none;
	}
}

.image-grid {
	.grid-item,
	.grid-sizer {
		width         : calc(33% - 17.5px);
		margin-bottom : 30px;
	}
}

.gallery-image {
	display             : block;
	position            : relative;
	width               : 100%;
	padding-top         : 75%;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;

	.overlay {
		@include           quart-transition(400ms);
		display          : flex;
		position         : absolute;
		top              : 0;
		right            : 0;
		bottom           : 0;
		left             : 0;
		background-color : transparentize($black, 1);
		align-items      : center;
		justify-content  : center;

		.button {
			@include           quart-transition(400ms);
			display          : flex;
			width            : 40px;
			height           : 40px;
			color            : $white;
			background-color : $blue;
			align-items      : center;
			justify-content  : center;
			transform        : scale(0);
		}
	}

	&:hover {
		.overlay {
			background-color : transparentize($black, .7);

			.button {
				transform : scale(1);
			}
		}
	}

	&.double-height {
		padding-top : 150%;
	}

	&.icon-show{
		.overlay {
			background-color : transparentize($black, .7);

			.button {
				transform : scale(1);
			}
		}
	}
}

// ==========[ PRODUCT DETAIL ]==========

.detail-nav {
	display         : flex;
	position        : absolute;
	top             : 450px;
	right           : 0;
	left            : 0;
	align-items     : center;
	justify-content : space-between;

	a {
		display          : flex;
		width            : 40px;
		height           : 40px;
		color            : $white;
		background-color : $blue;
		align-items      : center;
		font-size        : 18px;
		justify-content  : center;

		&:hover {
			@include           softshadow;
			color            : $blue;
			background-color : $white;
		}
	}
}

.detail-gallery {
	.big-image {
		@include              quart-transition(400ms);
		position            : relative;
		width               : 100%;
		margin-bottom       : 30px;
		padding-top         : 75%;
		background-repeat   : no-repeat;
		background-position : center;
		background-size     : cover;

		.icon {
			@include           quart-transition(400ms);
			display          : flex;
			position         : absolute;
			top              : 0;
			left             : 0;
			width            : 40px;
			height           : 40px;
			color            : $white;
			background-color : $blue;
			align-items      : center;
			justify-content  : center;
		}

		&:hover {
			@include    softshadow;
			transform : scale(1.05);

			.icon {
				transform        : scale(1.25);
				transform-origin : left top;
			}
		}
	}

	.thumbnails {
		.thumbnail {
			@include              quart-transition(400ms);
			display             : block;
			width               : 100%;
			padding-top         : 75%;
			background-repeat   : no-repeat;
			background-position : center;
			background-size     : cover;
			box-shadow          : inset 0px 0px 0px 0px rgba(82,86,103,1);

			&.active,
			&:hover {
				box-shadow : inset 0px 0px 0px 4px rgba(82,86,103,1);
			}
		}

		.thumbnail-button {
			@include      quart-transition(400ms);
			display     : block;
			position    : relative;
			width       : 100%;
			padding-top : 75%;

			.inner {
				@include           quart-transition(400ms);
				display          : flex;
				position         : absolute;
				top              : 0;
				right            : 0;
				bottom           : 0;
				left             : 0;
				color            : $white;
				background-color : $blue;
				align-items      : center;
				justify-content  : center;
			}

			&:hover {
				@include   softshadow;

				.inner {
					color            : $blue;
					background-color : $white;
				}
			}
		}
	}
}

.lazyload, .lazyloading{
	opacity: 0;
}

.lazyloaded{
	opacity: 1;
	transition: opacity 0.2s ease;
}

.lazyload-wrapper{
	position: relative;
	background: $grey-lightest;
}

.cta {
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.4);
	}

	h1, h2, h3, h4, h5, h6, .cms, p {
		color: $white;
	}
} 

.header_fixed {
	position: fixed;
	width: 100%;
	z-index: 5;
	background-color: $white;
}

@media (max-width: 960px) {
	.header_fixed {
		position: inherit;
		width: inherit;
		z-index: inherit;
	}	
}

.ig-logo {
	width: 14px;
}

.ig-logo svg {
	fill: #9b9b9b !important;
	min-width: 12px;
	transition: fill 0.2s ease;
	width: 12px;
}

.ig-logo:hover svg {
	fill: #e90100 !important;
}
