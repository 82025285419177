// ==========[ FONTS ]==========

$ff-lora  : 'Lora', serif;
$ff-karla : 'Karla', sans-serif;

// ==========[ COLORS ]==========

$white         : #FFFFFF;
$grey-lightest : #F4F4F4;
$grey-light    : #EFEFEF;
$grey          : #9B9B9B;
$grey-dark     : #4C4C4C;
$black         : #1B1919;

$blue          : #525667;

// ==========[ MIXINS ]==========

@mixin boxshad() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@mixin softshadow() {
  box-shadow: 0px 10px 30px -5px rgba(0,0,0,.3);
}

@mixin quart-transition($duration) {
  transition: all $duration cubic-bezier(0.770, 0.000, 0.175, 1.000);
}
